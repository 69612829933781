/* Provide sufficient contrast against white background */
a {
    color: #0366d6;
}

code {
    color: #E01A76;
}

.btn-primary {
    color: #fff;
    background-color: #1b6ec2;
    border-color: #1861ac;
}

.volunteer-group-dropdown-colourbox {
    width: 12px;
    height: 12px;
    display: inline-block;
    border: 1px solid black;
    padding: 0px;
    margin-right: 5px;
}

.CardHeadLink {
    display: inline-block;
    text-align: center;
    vertical-align: middle;
    padding-top: 2px;
    padding-bottom: 5px;
}

    .CardHeadLink:link {
        color: #ffffff;
    }

    .CardHeadLink:visited {
        color: #ffffff;
    }

.CardBodyLink {
}

    .CardBodyLink:link {
        color: #337ab7;
    }

    .CardBodyLink:visited {
        color: #337ab7;
    }

h4.card-title {
    margin: 0 !important;
    /*vertical-align: middle;*/
}

.card-header {
    background-color: #337ab7;
    border-color: #337ab7;
    color: #ffffff;
}

.card {
    border-color: #337ab7;
    min-width: 220px;
    margin-bottom: 10px !important;
}

.card-link {
    font-weight: bold;
}

    .card-link:link {
    }

    .card-link:visited {
    }

    .card-link:hover {
    }

.navbar-expand-sm {
    /*background-image: url(/images/header-large.jpg);
    background-repeat:no-repeat;
    background-size:cover;
        */
}

.navbar-collapse {
    background-color: #337ab7;
}

.nav {
    background-color: #337ab7;
    color: #ffffff !important;
}

.bg-dark {
    background-color: #337ab7 !important;
}

/*header {
    width:100%;
    margin-bottom: 10px;
}
*/
.navbar {
    background-color: #337ab7;
}

.nav-link {
    vertical-align: middle;
}
.nav-link-icon{
    margin-bottom:4px;
}
.dropdown-toggle {
    color: #ffffff;
}

.dropdown-menu {
    background-color: #337ab7;
}
.dropdown-item {
    background-color: #337ab7;
}
    .dropdown-item:hover {
        background-color: #4d89bd;
    }

.modal-header {
    background-color: #337ab7;
    color: #ffffff;
}


.modal-body .row:nth-child(even) {
    background-color: #f8f9fa;
}
.modal-body .row:nth-child(odd) {
    background-color: #ffffff;
}



.rbc-time-view .rbc-time-content {
    display: none;
}

.doughnutEditorCategoryRow.row {
    border-bottom-style: solid !important;
    border-bottom: black;
    border-bottom-width: 1px;
    background-color: lightblue;
}
.doughnutEditorSegmentRow {
    border-bottom-style: solid !important;
    border-bottom: lightgray;
    border-bottom-width: 1px;
}
/* ***********************************************************************************************************


*/
/* */
#evcontainer {
    width: 100%;
    font-family: 'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande', 'Lucida Sans', Arial, sans-serif;
}

#evtitle {
}

#evchart {
}

.evDataBound {
    cursor: pointer;
}
/* */
.evChartContainer {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    width: 100%;
}

.evChartPanel {
    display: flex;
    flex-direction: column;
    flex-basis: 100%;
    flex: 3;
    min-width: 700px;
}

.evChartDataPanel {
    display: flex;
    flex-direction: column;
    flex-basis: 100%;
    flex: 1;
    min-width: 300px;
}

.evChartDataBlockGroup {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    width: 100%;
}


.evChartDataBlock {
    display: flex;
    flex-direction: column;
    flex-basis: 100%;
    flex: 1;
    min-width: 210px;
    min-height: 150px;
    margin: 10px;
}

#evChartDataBlockResearch {
    max-height: 250px;
    overflow: auto;
}

    #evChartDataBlockResearch li {
        padding-bottom: 4px;
    }

    #evChartDataBlockResearch a {
        text-decoration: none;
    }

        #evChartDataBlockResearch a:hover {
            text-decoration: underline;
        }

        #evChartDataBlockResearch a:visited {
            text-decoration: none;
        }
/*.evChartDataBlock:nth-of-type(2) {
        background: #D20240;
    }
        .evChartDataBlock:nth-of-type(2) > div:nth-of-type(3) {
            color:#ffffff;
            min-height: 150px;
        }
    .evChartDataBlock > div:nth-of-type(1) {
        height: 10px;
    }
    .evChartDataBlock > div {
        width: 100%;
        
    }
        .evChartDataBlock > div:nth-of-type(2) {
            background:#ffffff;
            text-transform: uppercase;
        }
        .evChartDataBlock > div:nth-of-type(3) {
            min-height: 150px;
        }*/
/* */
.evChartNavTitle {
    background: #2C3F73;
    color: #ffffff;
    padding: 10px;
}

.evChartNav {
    background: #2C3F73;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    width: 100%;
}

.evChartNavBlock {
    display: flex;
    flex-direction: column;
    flex-basis: 100%;
    flex: 3;
    min-width: 200px;
    align-items: center;
    padding-top: 10px;
    padding-bottom: 10px;
}

.evMiniChart {
    cursor: pointer;
    background: #ffffff;
    width: 200px;
    height: 200px;
    text-align: center;
    vertical-align: middle;
    display: flex;
    align-items: center;
}

    .evMiniChart > SVG {
        padding: 20px;
        width: 100%;
        height: 100%;
    }

.evMiniChartColumn {
}

.evMiniChartTitle {
    cursor: pointer;
    width: 200px;
    background: #9CC46F;
    text-align: center;
    font-weight: bold;
    padding-top: 5px;
    padding-bottom: 5px;
}
/* */
.donutText {
    font-family: 'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande', 'Lucida Sans', Arial, sans-serif;
    font-weight: normal;
    text-transform: uppercase;
}

.sfInnerChartDonutArcs {
    fill: #D20240;
}

.sfDonutArcs {
    fill: #94B5CA;
}

.sfDonutArcsOuter {
    fill: #30633A;
}

.sfTextOuter {
    fill: white;
    font-size: 10pt;
}

.sfText {
    font-size: 12pt;
}

.sfTextSmall {
    font-size: 8pt;
}
/* ecological ceiling styles*/
.ecText {
    font-size: 10pt;
}

.ecTextInner {
    fill: white;
    font-size: 10pt;
}

.ecOuterChartDonutArcs {
    fill: #D20240;
}

.ecDonutArcs {
    fill: #99C471;
}

.ecDonutArcsInner {
    fill: #30633A;
}
/* core styles */
.npcDonutArcs {
    fill: #804C8C;
}

.npcText {
    font-size: 10pt;
    fill: #FFFFFF;
}
/**/
#evtooltip {
    font-family: 'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande', 'Lucida Sans', Arial, sans-serif;
    position: absolute;
    display: none;
    background: white;
    border: 1px solid black;
    border-radius: 5px;
    padding: 0px;
    max-width: 250px;
    min-width: 250px;
}

.evTooltipTitle {
    padding: 5px;
    background: #26559C;
    color: white;
    display: block;
    white-space: nowrap;
}

.evTooltipContent {
    padding: 5px;
    display: block;
}

.evTooltipFooter {
}
/**/
.evTopNav {
    display: flex;
    /*flex-direction: row;*/
    flex-wrap: wrap;
    width: 100%;
    background: #2C3F73;
    color: #ffffff;
}

.evTopNavItem {
    display: flex;
    /*flex-direction: column;*/
    /*flex-basis: 100%;*/
    /*flex: 25%;*/

    padding: 10px;
    align-items: center;
}

    .evTopNavItem IMG {
        vertical-align: middle;
        padding-right: 5px;
    }

    .evTopNavItem A {
        color: #ffffff;
        text-decoration: none;
        border-bottom: none;
    }

        .evTopNavItem A:hover {
            color: #ffffff;
            text-decoration: underline;
        }

        .evTopNavItem A:visited {
            text-decoration: none;
        }

.evTopNavSearchItem {
    /*display: flex;*/
    padding: 10px;
    padding-left: 10px;
    /*flex-direction: column;*/
    /*flex-basis: 100%;*/
    /*flex: 25%;*/
}

    .evTopNavSearchItem IMG {
        width: 20px;
        vertical-align: middle;
        padding-right: 5px;
    }

#evSearchForLabel {
    padding-right: 5px;
}